import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect } from 'react';

import LOYALBUDS from "../media/images/loyalbuds_white_pro.png";
import { FaAppStore, FaDiscord, FaGooglePlay, FaInstagram, FaTwitter, FaSignOutAlt, FaLayerGroup, FaCheck, FaReceipt, FaUser } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_OPTIONS } from '../constants';
import BusinessService from '../services/business_services';
import LOADER from "../media/images/loader.gif";
import TokenService from '../services/token_services';

//TRANSLATION
import { useTranslation } from 'react-i18next';

function Account() {
    const { t } = useTranslation();
    const [title, setTitle] = useState("Mon compte");
    const [merchant, setMerchant] = useState({});
    const [email, setEmail] = useState();
    const [firstname, setFirstname] = useState();
    const [lastname, setLastname] = useState();
    const [phone, setPhone] = useState();
    const [oldPswd, setOldPswd] = useState();
    const [newPswd, setNewPswd] = useState();
    const [processing, setProcessing] = useState(false);

    const loadAccount = async () => {
        const data = await BusinessService.business()
        setMerchant({
            name: data.business.business_name,
            website: data.business.website,
            address: data.business.address,
            city: data.business.city,
            country: data.business.country,
            description: data.business.description,
            logo: data.business.logo || data.business.logo_url,
            pro: data.business,
            user: data.user,
            business_activity_uuid: data.business.business_activity_uuid,
        })
        setEmail(data.user.email_address)
        setFirstname(data.user.firstname)
        setLastname(data.user.lastname)
        setPhone(data.user.phone_number)
    }

    const update = async () => {
        if (!email || !phone || !firstname || !lastname)
            toast.error('Veuillez renseigner toutes les informations requises', TOAST_OPTIONS)
        else {
            try {
                setProcessing(true)
                const status = await BusinessService.updateAccount(firstname,
                    lastname,
                    email,
                    phone)
                if (status) {
                    setProcessing(false)
                    toast.success("Compte utilisateur mis à jour avec succès", TOAST_OPTIONS)
                } else
                    toast.error("Mise à jour du compte échouée", TOAST_OPTIONS)
            } catch (e) {
                setProcessing(false)
                toast.error(e.response && e.response.data ? e.response.data.message : e.message, TOAST_OPTIONS)
            }
        }
    }

    const updatePswd = async () => {
        if (!oldPswd || !newPswd || (newPswd.length < 8))
            toast.error('Veuillez renseigner un mot de passe avec min. 8 caractères', TOAST_OPTIONS)
        else {
            try {
                setProcessing(true)
                const status = await BusinessService.updateAccountPswd(
                    oldPswd,
                    newPswd)
                if (status) {
                    setProcessing(false)
                    toast.success("Mot de passe mis à jour avec succès", TOAST_OPTIONS)
                } else
                    toast.error("Mise à jour du mot de passe échouée", TOAST_OPTIONS)
            } catch (e) {
                setProcessing(false)
                toast.error(e.response && e.response.data ? e.response.data.message : e.message, TOAST_OPTIONS)
            }
        }
    }

    useEffect(() => {
        loadAccount();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="container">
            <header>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <div className="menu">
                    <div className="entry" onMouseOver={() => setTitle("Mes Buds")} onMouseOut={() => setTitle("Mon commerce")} onClick={() => window.location.href = "/merchants/buds"}>
                        <FaLayerGroup />
                    </div>
                    <div className="merchant" style={{
                        backgroundImage:
                            merchant.logo ?
                                `url('${merchant.logo}')`
                                :
                                merchant.logo_url ?
                                    `url('${merchant.logo_url}')`
                                    :
                                    `url('https://api.dicebear.com/7.x/identicon/svg?seed=${merchant.name}')`
                    }} onMouseOver={() => setTitle("Mon commerce")} onMouseOut={() => setTitle("Mon commerce")} onClick={() => window.location.href = "/merchants/profile"}></div>
                    <div className="entry" onMouseOver={() => setTitle("Mes achats")} onMouseOut={() => setTitle("Mon commerce")} onClick={() => window.location.href = "/merchants/receipts"}>
                        <FaReceipt />
                    </div>
                    <div className="entry active" onMouseOver={() => setTitle("Mon compte")} onMouseOut={() => setTitle("Account")} onClick={() => window.location.href = "/merchants/account"}>
                        <FaUser />
                    </div>
                    <div className="entry" onMouseOver={() => setTitle("Déconnexion")} onMouseOut={() => setTitle("Mon commerce")} onClick={TokenService.removeUser}>
                        <FaSignOutAlt />
                    </div>
                </div>
            </header>
            <div className="content simple">
                <span className="notice">{title}</span>
                <h1 className="title">
                    Votre compte
                </h1>
                <p>
                    Veuillez remplir toutes les informations demandées
                </p>
                <div className="form">
                    <label>
                        Prénom
                    </label>
                    <input type="text" value={firstname} onChange={e => setFirstname(e.target.value)} />
                    <label>
                        Nom de famille
                    </label>
                    <input type="text" value={lastname} onChange={e => setLastname(e.target.value)} />
                    <label>
                        Email
                    </label>
                    <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
                    <label>
                        Numéro de téléphone
                    </label>
                    <input type="text" value={phone} onChange={e => setPhone(e.target.value)} />
                    {processing ?
                        <img src={LOADER} width="100" alt="Running man" />
                        :
                        <div className="button" onClick={update}>
                            <FaCheck /> Mettre à jour
                        </div>}
                </div>
                <h3 className="title" style={{ marginTop: "100px" }}>
                    Changer de mot de passe
                </h3>
                <p>
                    Veuillez remplir toutes les informations demandées
                </p>
                <div className="form">
                    <label>
                        Mot de passe actuel
                    </label>
                    <input type="password" value={oldPswd} onChange={e => setOldPswd(e.target.value)} />
                    <label>
                        Nouveau mot de passe
                    </label>
                    <input type="password" value={newPswd} onChange={e => setNewPswd(e.target.value)} />
                    {processing ?
                        <img src={LOADER} width="100" alt="Running man" />
                        :
                        <div className="button" onClick={updatePswd}>
                            <FaCheck /> Mettre à jour
                        </div>}
                </div>
            </div>
            <footer>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <p className="privacy">
                    <a href="/privacy">{t('privacy.policy')}</a> | <a href="/cgu">{t('cgu.general_terms')}</a>
                </p>
                <p className="copyright">
                    {t('home.website')}
                    <br />
                    <strong>Copyright @Codinsight. 2024</strong>
                </p>
                <div className="entry">
                    <p>
                        <strong>Editeur du Site</strong>
                    </p>
                    <p>
                        L'éditeur du Site est Codinsight, société par actions simplifiée au capital de 8000€,
                        immatriculée 907 943 088 au RCS de Montpellier.
                    </p>
                    <p>
                        N° TVA : FR18907943088
                        <br />
                        Email : contact@codinsight.com
                        <br />
                        Directeur de publication : M. Fabien BUCAMP.
                    </p>
                </div>
                <div className="entry">
                    <p>
                        <strong>Hébergeur du Site</strong>
                    </p>
                    <p>
                        Ce site est hébergé exclusivement sur des datacenters en France (Paris) de la société Amazon Web Services
                    </p>
                    <p>
                        Amazon Web Services Inc.
                        <br />
                        410 Terry Avenue North,
                        <br />
                        Seattle, WA 98109-5210, USA
                    </p>
                </div>
                <div className="entry">
                    <div className="link" onClick={() => window.open("https://ios.loyalbuds.app")}>
                        <FaAppStore />
                    </div>
                    <div className="link" onClick={() => window.open("https://play.loyalbuds.app")}>
                        <FaGooglePlay />
                    </div>
                    <div className="link" onClick={() => window.open("https://discord.com/invite/sHbvCwhPGz")}>
                        <FaDiscord />
                    </div>
                    <div className="link" onClick={() => window.open("https://twitter.com/LoyalBuds")}>
                        <FaTwitter />
                    </div>
                    <div className="link" onClick={() => window.open("https://www.instagram.com/loyalbudsgame/")}>
                        <FaInstagram />
                    </div>
                </div>
            </footer>
            <ToastContainer />
        </div>
    );
}

export default Account;

import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect } from 'react';
import BusinessService from '../services/business_services';
import CardService from '../services/card_services';
import LOYALBUDS from "../media/images/loyalbuds_white_pro.png";
import Tilt from 'react-parallax-tilt';
import { FaAppStore, FaDiscord, FaGooglePlay, FaInstagram, FaTwitter, FaSignOutAlt, FaLayerGroup, FaTimes, FaQrcode, FaPlus, FaCreditCard, FaReceipt, FaUser } from "react-icons/fa";
import { isMobile } from 'react-device-detect';
import LOADER from "../media/images/loader.gif";
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_OPTIONS } from '../constants';
import TokenService from '../services/token_services';

//TRANSLATION
import { useTranslation } from 'react-i18next';

function MerchantBuds() {
    const { t } = useTranslation();
    const [title, setTitle] = useState("Mes Buds");
    const [merchant, setMerchant] = useState({});
    const [buds, setBuds] = useState([]);
    const [selectedBud, setSelectedBud] = useState();
    const [hideBud, setHidebud] = useState(false);
    const [addCard, setAddCard] = useState(false);
    const [displayDesc, setDisplayDesc] = useState(false);
    const [unitPrice, setUnitPrice] = useState(0);
    const [quantity, setQuantity] = useState(100);
    const [processing, setProcessing] = useState(false);

    const loadMerchant = async () => {
        const data = await BusinessService.business()
        setMerchant({
            name: data.business.business_name,
            website: data.business.website,
            address: data.business.address,
            city: data.business.city,
            country: data.business.country,
            description: data.business.description,
            logo: data.business.logo || data.business.logo_url,
            pro: data.business,
            user: data.user
        })
    }

    const loadBuds = async () => {
        const buds = await BusinessService.budsBusiness()
        setBuds(buds)
        const card_unit_price = await CardService.getUnitPrice()
        setUnitPrice(parseFloat(card_unit_price).toFixed(2))
    }

    const triggerSelection = (bud_uuid) => {
        setSelectedBud(bud_uuid)
        setHidebud(true)
        setTimeout(() => {
            setDisplayDesc(true)
        }, 500);
    }

    const cancelSelection = () => {
        setSelectedBud(null)
        setHidebud(false)
        setDisplayDesc(false)
    }

    const purchaseNewItems = async (bud_uuid) => {
        if (quantity < 100) {
            toast.warn('Veuillez renseigner une quantité de min. 100 cartes', TOAST_OPTIONS)
        } else {
            setProcessing(true)
            const status = await CardService.purchaseBusinessBud(bud_uuid, parseInt(quantity))
            setProcessing(false)
            setAddCard(null)
            if (status) {
                toast.success("Achat confirmé. Merci de votre confiance.", TOAST_OPTIONS)
                loadBuds()
            } else
                toast.error("L'achat de vos cartes a échoué", TOAST_OPTIONS)
        }
    }

    useEffect(() => {
        loadMerchant();
        loadBuds();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="container">
            <header>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <div className="menu">
                    <div className="entry active" onMouseOver={() => setTitle("Mes Buds")} onMouseOut={() => setTitle("Mes Buds")} onClick={() => window.location.href = "/merchants/buds"}>
                        <FaLayerGroup />
                    </div>
                    <div className="merchant" style={{
                        backgroundImage:
                            merchant.logo ?
                                `url('${merchant.logo}')`
                                :
                                merchant.logo_url ?
                                    `url('${merchant.logo_url}')`
                                    :
                                    `url('https://api.dicebear.com/7.x/identicon/svg?seed=${merchant.name}')`
                    }} onMouseOver={() => setTitle("Mon commerce")} onMouseOut={() => setTitle("Mes Buds")} onClick={() => window.location.href = "/merchants/profile"}></div>
                    <div className="entry" onMouseOver={() => setTitle("Mes achats")} onMouseOut={() => setTitle("Mes Buds")} onClick={() => window.location.href = "/merchants/receipts"}>
                        <FaReceipt />
                    </div>
                    <div className="entry" onMouseOver={() => setTitle("Mon compte")} onMouseOut={() => setTitle("Account")} onClick={() => window.location.href = "/merchants/account"}>
                        <FaUser />
                    </div>
                    <div className="entry" onMouseOver={() => setTitle("Déconnexion")} onMouseOut={() => setTitle("Mes Buds")} onClick={TokenService.removeUser}>
                        <FaSignOutAlt />
                    </div>
                </div>
            </header>
            <div className="content simple">
                <span className="notice">{title}</span>
                <h1 className="title">
                    Gérer vos Buds et leur distribution
                </h1>
                <p>
                    Sélectionnez un de vos Buds et gérer sa distribution
                </p>
                <div className="card_holder">
                    {buds.map(bud =>
                        <div className="aligner" key={bud.bud_uuid}
                            style={{
                                transform: (selectedBud === bud.bud_uuid) && hideBud ? "scale(1.1)" : "scale(1)",
                                display: (selectedBud !== bud.bud_uuid && hideBud) ? "none" : "inline-block",
                                marginLeft: (!isMobile && selectedBud === bud.bud_uuid && hideBud) ? "-300px" : isMobile ? "0px" : "20px",
                                marginBottom: (isMobile && selectedBud === bud.bud_uuid && hideBud) ? "300px" : "0px",
                                transition: "all .5s ease-out",
                            }}>
                            {selectedBud === bud.bud_uuid && !addCard && <div className="desc" style={{ opacity: displayDesc ? 1 : 0 }}>
                                <small>A propos de ce Bud</small>
                                <hr />
                                {bud.bud_info.description && bud.bud_info.description.fr ? bud.bud_info.description.fr.length > 100 ? `${bud.bud_info.description.fr.substring(0, 100)}...` : bud.bud_info.description.fr : bud.bud_info.description}
                                <br />
                                <div className="button single" onClick={() => window.location.href = `/merchants/distribution/${bud.bud_uuid}`}>
                                    <FaQrcode /> Distribution
                                </div>
                                <div className="button single" onClick={() => setAddCard(bud.bud_uuid)}>
                                    <FaPlus /> Réapprovisionner
                                </div>
                                <div className="cancel single" onClick={cancelSelection}>
                                    <FaTimes /> Fermer
                                </div>
                            </div>}
                            {addCard === bud.bud_uuid && <div className="desc" style={{ opacity: displayDesc ? 1 : 0 }}>
                                <small>Acheter de nouvelles cartes</small>
                                <hr />
                                <input type="number" placeholder="Quantité" onChange={(e) => setQuantity(e.target.value)} value={quantity} />
                                <small>Prix unitaire: {unitPrice}<small>EUR</small></small>
                                <br />
                                <strong>Valeur d'achat : {parseFloat(quantity * unitPrice).toFixed(2)}<small>EUR</small></strong>
                                <br />
                                {processing ?
                                    <img src={LOADER} width="100" alt="Running man" />
                                    :
                                    <div className="button single" onClick={() => purchaseNewItems(bud.bud_uuid)}>
                                        <FaCreditCard /> Confirmer achat
                                    </div>}
                                <div className="cancel single" onClick={() => setAddCard(null)}>
                                    <FaTimes /> Annuler
                                </div>
                            </div>}
                            <Tilt>
                                <div className={`card ${bud.bud_info.bud_level}`}>
                                    <h2 className="serie">{bud.bud_info.bud_name}</h2>
                                    <h5 className="rarity">{bud.bud_info.bud_level.toUpperCase()}</h5>
                                    <div className="power"
                                        onClick={() => triggerSelection(bud.bud_uuid)}>
                                        <big>{bud.nb_item - bud.nb_distributed}</big>
                                        <span>
                                            <small>disponibles</small>
                                        </span>
                                    </div>
                                    <div className="bud" style={{ backgroundImage: `url('${bud.bud_info.img_url}')` }}
                                        onClick={() => triggerSelection(bud.bud_uuid)}></div>
                                </div>
                            </Tilt>
                        </div>)}
                    <div className="button d-block" onClick={() => window.location.href = "/merchants/buds/new"}>
                        <FaPlus /> Acheter un nouveau Bud
                    </div>
                </div>
            </div>
            <footer>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <p className="privacy">
                    <a href="/privacy">{t('privacy.policy')}</a> | <a href="/cgu">{t('cgu.general_terms')}</a>
                </p>
                <p className="copyright">
                    Le site Internet loyalbuds.io est la propriété de Codinsight SAS
                    <br />
                    <strong>Copyright @Codinsight. 2024</strong>
                </p>
                <div className="entry">
                    <p>
                        <strong>Editeur du Site</strong>
                    </p>
                    <p>
                        L'éditeur du Site est Codinsight, société par actions simplifiée au capital de 8000€,
                        immatriculée 907 943 088 au RCS de Montpellier.
                    </p>
                    <p>
                        N° TVA : FR18907943088
                        <br />
                        Email : contact@codinsight.com
                        <br />
                        Directeur de publication : M. Fabien BUCAMP.
                    </p>
                </div>
                <div className="entry">
                    <p>
                        <strong>Hébergeur du Site</strong>
                    </p>
                    <p>
                        Ce site est hébergé exclusivement sur des datacenters en France (Paris) de la société Amazon Web Services
                    </p>
                    <p>
                        Amazon Web Services Inc.
                        <br />
                        410 Terry Avenue North,
                        <br />
                        Seattle, WA 98109-5210, USA
                    </p>
                </div>
                <div className="entry">
                    <div className="link" onClick={() => window.open("https://ios.loyalbuds.app")}>
                        <FaAppStore />
                    </div>
                    <div className="link" onClick={() => window.open("https://play.loyalbuds.app")}>
                        <FaGooglePlay />
                    </div>
                    <div className="link" onClick={() => window.open("https://discord.com/invite/sHbvCwhPGz")}>
                        <FaDiscord />
                    </div>
                    <div className="link" onClick={() => window.open("https://twitter.com/LoyalBuds")}>
                        <FaTwitter />
                    </div>
                    <div className="link" onClick={() => window.open("https://www.instagram.com/loyalbudsgame/")}>
                        <FaInstagram />
                    </div>
                </div>
            </footer>
            <ToastContainer />
        </div>
    );
}

export default MerchantBuds;

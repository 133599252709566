import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect, useRef } from 'react';

import LOYALBUDS from "../media/images/loyalbuds_white_pro.png";
import { FaAppStore, FaDiscord, FaGooglePlay, FaInstagram, FaTwitter, FaSignOutAlt, FaLayerGroup, FaCheck, FaReceipt, FaUser } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_OPTIONS } from '../constants';
import BusinessService from '../services/business_services';
import LOADER from "../media/images/loader.gif";
import { Buffer } from 'buffer';
import TokenService from '../services/token_services';

//TRANSLATION
import { useTranslation } from 'react-i18next';

function Profile() {
    const { t, i18n } = useTranslation();
    const [title, setTitle] = useState("Mon commerce");
    const [merchant, setMerchant] = useState({});
    const [name, setName] = useState();
    const [website, setWebsite] = useState();
    const [address, setAddress] = useState();
    const [city, setCity] = useState();
    const [country, setCountry] = useState();
    const [activity, setActivity] = useState();
    const [activities, setActivities] = useState([]);
    const [descFR, setDescFR] = useState();
    const [descEN, setDescEN] = useState();
    const [fileExt, setFileExt] = useState();
    const [fileData, setFileData] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [processing, setProcessing] = useState(false);

    const inputRef = useRef();

    const loadMerchant = async () => {
        const data = await BusinessService.business()
        setMerchant({
            name: data.business.business_name,
            website: data.business.website,
            address: data.business.address,
            city: data.business.city,
            country: data.business.country,
            description: data.business.description,
            logo: data.business.logo || data.business.logo_url,
            pro: data.business,
            user: data.user,
            business_activity_uuid: data.business.business_activity_uuid,
            email: data.business.business_email,
            phone: data.business.business_phone,
        })
        setName(data.business.business_name)
        setWebsite(data.business.website)
        setAddress(data.business.address)
        setCity(data.business.city)
        setCountry(data.business.country)
        setDescFR(data.business.description && data.business.description.fr)
        setDescEN(data.business.description && data.business.description.en)
        setActivity(data.business.business_activity_uuid)
        setEmail(data.business.business_email)
        setPhone(data.business.business_phone)
        const activities = await BusinessService.businessActivity()
        setActivities(activities)
    }

    const handleFileUpload = () => { inputRef.current.click() }

    const onFileFileHandler = async (event) => {
        const reader = new FileReader()
        const extension = event.target.files[0] && event.target.files[0].name.split('.').pop().toLowerCase()
        if (!extension || !['jpg', 'png', 'jpeg'].includes(extension)) {
            toast.error("Fichier invalide, unqiuement une image acceptée", TOAST_OPTIONS)
        } else if (event.target.files[0].size > 12000000) {
            toast.error("Taille de l'image dépassant les 10Mo", TOAST_OPTIONS)
        } else {
            setFileExt(extension)
            reader.onloadend = () => processFile(reader)
            reader.readAsArrayBuffer(event.target.files[0])
        }
    }

    const processFile = async (reader) => {
        const buffer = Buffer.from(reader.result)
        const base64data = buffer.toString('base64')
        setFileData(base64data)
    }

    const update = async () => {
        if (!address || !city || !country || !name || (!fileData && !merchant.logo))
            toast.error('Veuillez renseigner toutes les informations requises', TOAST_OPTIONS)
        else {
            try {
                setProcessing(true)
                const status = await BusinessService.updateInfo(website,
                    address,
                    city,
                    country,
                    { en: descEN, fr: descFR },
                    fileData ? `data:image/${fileExt};base64,${fileData}` : null,
                    name,
                    activity,
                    email,
                    phone,)
                if (status) {
                    setProcessing(false)
                    toast.success("Profile commerce mis à jour avec succès", TOAST_OPTIONS)
                } else
                    toast.error("Mise à jour profile échouée", TOAST_OPTIONS)
            } catch (e) {
                setProcessing(false)
                toast.error(e.response && e.response.data ? e.response.data.message : e.message, TOAST_OPTIONS)
            }
        }
    }

    useEffect(() => {
        loadMerchant();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="container">
            <header>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <div className="menu">
                    <div className="entry" onMouseOver={() => setTitle("Mes Buds")} onMouseOut={() => setTitle("Mon commerce")} onClick={() => window.location.href = "/merchants/buds"}>
                        <FaLayerGroup />
                    </div>
                    <div className="merchant" style={{
                        backgroundImage:
                            merchant.logo ?
                                `url('${merchant.logo}')`
                                :
                                merchant.logo_url ?
                                    `url('${merchant.logo_url}')`
                                    :
                                    `url('https://api.dicebear.com/7.x/identicon/svg?seed=${merchant.name}')`
                    }} onMouseOver={() => setTitle("Mon commerce")} onMouseOut={() => setTitle("Mon commerce")} onClick={() => window.location.href = "/merchants/profile"}></div>
                    <div className="entry" onMouseOver={() => setTitle("Mes achats")} onMouseOut={() => setTitle("Mon commerce")} onClick={() => window.location.href = "/merchants/receipts"}>
                        <FaReceipt />
                    </div>
                    <div className="entry" onMouseOver={() => setTitle("Mon compte")} onMouseOut={() => setTitle("Account")} onClick={() => window.location.href = "/merchants/account"}>
                        <FaUser />
                    </div>
                    <div className="entry" onMouseOver={() => setTitle("Déconnexion")} onMouseOut={() => setTitle("Mon commerce")} onClick={TokenService.removeUser}>
                        <FaSignOutAlt />
                    </div>
                </div>
            </header>
            <div className="content simple">
                <span className="notice">{title}</span>
                <h1 className="title">
                    Votre profil marchand
                </h1>
                <p>
                    Veuillez remplir toutes les informations demandées
                </p>
                <div className="form">
                    <div className="merchant_logo"
                        onClick={handleFileUpload}
                        style={{
                            backgroundImage: fileData ? `url('data:image/${fileExt};base64,${fileData}')` :
                                merchant.logo ? `url('${merchant.logo}')` : merchant.logo_url ? `url('${merchant.logo_url}')` : `url('https://api.dicebear.com/7.x/identicon/svg?seed=${merchant.name}')`
                        }}>
                    </div>
                    <input className="d-none" type="file" ref={inputRef}
                        accept="image/png,image/jpeg"
                        onChange={(e) => onFileFileHandler(e)} />
                    <span className="info">
                        Cliquer sur le logo pour éditer
                        <br />
                        Ratio 1:1 recommandé
                    </span>
                    <label>
                        Nom de votre commerce
                    </label>
                    <input type="text" value={name} onChange={e => setName(e.target.value)} />
                    <label>
                        Type d'activité
                    </label>
                    <select onChange={(e) => setActivity(e.target.value)}>
                        {!activity && <option>Sélectionner une activité</option>}
                        {activities.map(ac => activity === ac.business_activity_uuid &&
                            <option key={ac.business_activity_uuid} value={ac.business_activity_uuid}>
                                {ac.activity_name[i18n.language.substring(0, 2)] ? ac.activity_name[i18n.language.substring(0, 2)] : ac.activity_name.en}
                            </option>
                        )}
                        {activities.map(ac => activity !== ac.business_activity_uuid &&
                            <option key={ac.business_activity_uuid} value={ac.business_activity_uuid}>
                                {ac.activity_name[i18n.language.substring(0, 2)] ? ac.activity_name[i18n.language.substring(0, 2)] : ac.activity_name.en}
                            </option>
                        )}
                    </select>
                    <label>
                        Site internet
                    </label>
                    <input type="text" value={website} onChange={e => setWebsite(e.target.value)} />
                    <label>
                        Adresse
                    </label>
                    <input type="text" value={address} onChange={e => setAddress(e.target.value)} />
                    <label>
                        Ville
                    </label>
                    <input type="text" value={city} onChange={e => setCity(e.target.value)} />
                    <label>
                        Pays
                    </label>
                    <input type="text" value={country} onChange={e => setCountry(e.target.value)} />
                    <label>
                        Description [fr]
                    </label>
                    <input type="text" value={descFR} onChange={e => setDescFR(e.target.value)} />
                    <label>
                        Description [en]
                    </label>
                    <input type="text" value={descEN} onChange={e => setDescEN(e.target.value)} />
                    <label>
                        Email
                    </label>
                    <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
                    <label>
                        Phone
                    </label>
                    <input type="text" value={phone} onChange={e => setPhone(e.target.value)} />
                    {processing ?
                        <img src={LOADER} width="100" alt="Running man" />
                        :
                        <div className="button" onClick={update}>
                            <FaCheck /> Mettre à jour
                        </div>}
                </div>
            </div>
            <footer>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <p className="privacy">
                    <a href="/privacy">{t('privacy.policy')}</a> | <a href="/cgu">{t('cgu.general_terms')}</a>
                </p>
                <p className="copyright">
                    {t('home.website')}
                    <br />
                    <strong>Copyright @Codinsight. 2024</strong>
                </p>
                <div className="entry">
                    <p>
                        <strong>Editeur du Site</strong>
                    </p>
                    <p>
                        L'éditeur du Site est Codinsight, société par actions simplifiée au capital de 8000€,
                        immatriculée 907 943 088 au RCS de Montpellier.
                    </p>
                    <p>
                        N° TVA : FR18907943088
                        <br />
                        Email : contact@codinsight.com
                        <br />
                        Directeur de publication : M. Fabien BUCAMP.
                    </p>
                </div>
                <div className="entry">
                    <p>
                        <strong>Hébergeur du Site</strong>
                    </p>
                    <p>
                        Ce site est hébergé exclusivement sur des datacenters en France (Paris) de la société Amazon Web Services
                    </p>
                    <p>
                        Amazon Web Services Inc.
                        <br />
                        410 Terry Avenue North,
                        <br />
                        Seattle, WA 98109-5210, USA
                    </p>
                </div>
                <div className="entry">
                    <div className="link" onClick={() => window.open("https://ios.loyalbuds.app")}>
                        <FaAppStore />
                    </div>
                    <div className="link" onClick={() => window.open("https://play.loyalbuds.app")}>
                        <FaGooglePlay />
                    </div>
                    <div className="link" onClick={() => window.open("https://discord.com/invite/sHbvCwhPGz")}>
                        <FaDiscord />
                    </div>
                    <div className="link" onClick={() => window.open("https://twitter.com/LoyalBuds")}>
                        <FaTwitter />
                    </div>
                    <div className="link" onClick={() => window.open("https://www.instagram.com/loyalbudsgame/")}>
                        <FaInstagram />
                    </div>
                </div>
            </footer>
            <ToastContainer />
        </div>
    );
}

export default Profile;

import api from './api';
import TokenService from './token_services';

class BusinessService {
  async register(user_email, password, user_device) {
    return await api
      .post('/business/user/register', { user_email, password, user_device })
      .then(async (response) => {
        return response.data.status;
      });
  }
  async confirmRegistration(user_email, password, user_device, token) {
    return await api
      .post('/business/user/register', { user_email, password, user_device, "2fa_token": token })
      .then(async (response) => {
        console.log(response.data);
        if (response.data.jwt_token) {
          await TokenService.setUser(response.data);
        }
        return response.data.status;
      });
  }

  async searchBusiness(name, address, city, country) {
    return await api
      .post('/business/registration/search', { name, address, city, country })
      .then(async (response) => {
        return response.data;
      });
  }

  async registerBusiness(business_name, place_id) {
    return await api
      .post('/business/registration', { business_name, place_id })
      .then(async (response) => {
        if (response.data.jwt_token) {
          await TokenService.setUser(response.data);
        }
        return response.data.status;
      });
  }

  async login(email, password, device) {
    try {
      return await api
        .post('/business/user/login', { email, password, device })
        .then(async (response) => {
          return response.data.status;
        });
    } catch (e) { return false }
  }

  async getResetToken(email_address) {
    try {
      return await api
        .post('/business/user/password/reset/token', { email_address })
        .then(async (response) => {
          return response.data.status;
        });
    } catch (e) { return false }
  }

  async resetPswd(email_address, password, reset_token) {
    try {
      return await api
        .post('/business/user/password/reset', { email_address, password, reset_token })
        .then(async (response) => {
          return response.data.status;
        });
    } catch (e) { return false }
  }

  async TwoFAlogin(email, password, token, device) {
    try {
      return await api
        .post('/business/user/login', { email, password, "2fa_token": token, device })
        .then(async (response) => {
          if (response.data.jwt_token) {
            await TokenService.setUser(response.data);
          }
          return response.data;
        });
    } catch (e) { return false }
  }

  async updateInfo(website, address, city, country, description, logo, business_name, business_activity_uuid, business_email, business_phone) {
    return await api
      .post(`/business/update`, { website, address, city, country, description, logo, business_name, business_activity_uuid, business_email, business_phone })
      .then(async (response) => {
        return response.data.status;
      });
  }

  async business() {
    return await api
      .get('/business/account')
      .then(async (response) => {
        return response.data;
      });
  }

  async budsBusiness() {
    return await api
      .get('/business/bud')
      .then(async (response) => {
        return response.data.buds;
      });
  }

  async purchases() {
    return await api
      .get('/business/purchase')
      .then(async (response) => {
        return response.data.purchase_history;
      });
  }

  async setDefaultBud(bud_uuid) {
    return await api
      .post(`/business/bud/default`, { bud_uuid })
      .then(async (response) => {
        return response.data.status;
      });
  }

  async budDistributions(bud_uuid, expired = false) {
    return await api
      .get(`/business/bud/distribute/${bud_uuid}?expired=${expired}`)
      .then(async (response) => {
        console.log(response.data)
        return response.data.bud_distributions;
      });
  }

  async setBudDistribution(bud_uuid, nb_card, distribution_name, expiration_date, strict_location, distance, distribution_address, on_map, distribute_xp) {
    return await api
      .post(`/business/bud/distribute`, { bud_uuid, nb_card, distribution_name, expiration_date, strict_location, distance, distribution_address, on_map, distribute_xp })
      .then(async (response) => {
        return response.data.status;
      });
  }

  async stopBudDistribution(bud_uuid, distribution_uuid) {
    return await api
      .post(`/business/bud/distribute/stop`, { bud_uuid, distribution_uuid })
      .then(async (response) => {
        return response.data.status;
      });
  }

  async reactivateBudDistribution(distribution_name, expiration_date, distribution_uuid, nb_card, strict_location, distance, distribution_address, on_map, distribute_xp) {
    return await api
      .post(`/business/bud/distribute/reactivate`, { distribution_name, expiration_date, distribution_uuid, nb_card, strict_location, distance, distribution_address, on_map, distribute_xp })
      .then(async (response) => {
        return response.data.status;
      });
  }

  async updateAccount(firstname,
    lastname,
    email,
    phone) {
    return await api
      .post(`/business/user/update`, {
        firstname,
        lastname,
        email,
        phone
      })
      .then(async (response) => {
        return response.data.status;
      });
  }

  async updateAccountPswd(old_password, new_password) {
    return await api
      .post(`/business/user/update`, { old_password, new_password })
      .then(async (response) => {
        return response.data.status;
      });
  }

  async businessActivity() {
    return await api
      .get('/business/activity')
      .then(async (response) => {
        return response.data.activities;
      });
  }

  async businessDistributed(bud_uuid, distribution_token) {
    return await api
      .get(`/business/bud/distribute/users/${bud_uuid}/${distribution_token}`)
      .then(async (response) => {
        return response.data;
      });
  }

  // Only ADMIN
  async businesses() {
    return await api
      .get('/business')
      .then(async (response) => {
        return response.data.businesses;
      });
  }
}

// eslint-disable-next-line
export default new BusinessService();